import { Configuration, dto } from "@mrs/platform-configuration-webclient";
import { Form } from "../form/model/Form";
import { View } from "../view/model/View";
import { MenuItem } from "../menuItem/model/MenuItem";
import { MenuHook } from "../menuItem/model/MenuHook";
import { FormHook } from "../form/model/FormHook";
import { ViewHook } from "../view/model/ViewHook";
import { Broadcast } from "../../../infrastructure/broadcast/broadcast";
import { ConfigurationEvents } from "./ConfigurationEvents";
import { ReportTemplate } from "../reportTemplate/model/ReportTemplate";
import { IAppSettings } from "@utils/IAppSettings";
import { Nullable, ViewType } from "@mrs/webclient-shared-ui-lib";

class ConfigurationAccessClass {
    _configuration: Configuration;

    constructor() {
        this._configuration = new Configuration();
    }

    async init(url: string): Promise<boolean> {
        const result = await (this._configuration.init(url) as Promise<
            boolean
        >);
        this.isInitialized &&
            Broadcast.trig(ConfigurationEvents.onInitialized, null);
        return result;
    }

    get isInitialized(): boolean {
        return this._configuration.isInitialized;
    }

    clean() {
        this._configuration.clean();
    }

    getForm(name: string): Form | undefined {
        const dto = JSON.parse(this._configuration.form.getAsJson(name));
        if (!dto) return;
        return new Form(dto);
    }

    getView(name: string): View | undefined {
        const dto = this._configuration.view.get(name);
        if (!dto) return;
        return new View(dto);
    }

    getFormComponents() {
        try {
            return this._configuration.library.getModulesByType("form");
        } catch (e) {
            return [];
        }
    }

    getViewComponentByType(viewType: ViewType) {
        try {
            return this._configuration.library.getModuleByViewType(viewType);
        } catch (e) {
            return null;
        }
    }

    getMenuItems(): MenuItem[] {
        const dto = this._configuration.menu.getItems();
        return this.toEntities(MenuItem, dto);
    }

    getMenuHook(): MenuHook | null {
        const dto = this._configuration.menu.getHook();
        return dto ? new MenuHook(dto) : null;
    }

    getViewHooks = (viewName: string, name?: string) => {
        const dto = this._configuration.hook.getViewHooks(viewName, name);
        return this.toEntities(ViewHook, dto);
    };

    getHooks(formName?: string, hookType?: dto.hook.HOOK_TYPE): FormHook[] {
        const dto = this._configuration.hook.getAll(formName, hookType);
        return this.toEntities(FormHook, dto);
    }

    // удалить метод
    getViewDataHooks = (viewName: string) => {
        return this.getViewHooks(viewName, "viewData");
    };

    getCanCreateHooks(formId?: Nullable<string>) {
        return !!formId
            ? this.getHooks(formId, dto.hook.HOOK_TYPE.CAN_CREATE)
            : [];
    }

    getReportTemplates(location: string) {
        const dto = this._configuration.doctemplate.getAll(location);
        return this.toEntities(ReportTemplate, dto);
    }

    getConfigurationSettings(): IAppSettings {
        return this._configuration.manifest.getApp() as IAppSettings;
    }

    private toEntities<TEntity, TDto>(
        domainClass: new (dto: TDto) => TEntity,
        rawObjects: TDto[],
    ) {
        return rawObjects.map((raw) => new domainClass(raw));
    }
}

export const ConfigurationAccess = new ConfigurationAccessClass();
