import { CRUDRepository } from "../../../../sharedKernel/repository/impl/CRUDRepository";
import { IDictionaryRepository } from "../IDictionaryRepository";
import { Dictionary } from "../../model/Dictionary";
import { IDictionaryCreateDto } from "../../dto/IDictionaryCreateDto";
import { EntityMapper } from "../../../../sharedKernel/mapper/EntityMapper";
import { IDictionaryApiService } from "../../apiService/IDictionaryApiService";
import {
    IDictionaryDto,
    IDictionaryItemDto,
    IDictionaryItemRequestDto,
    ObjectId,
} from "@mrs/webclient-shared-ui-lib";

export class DictionaryRepository
    extends CRUDRepository<Dictionary, IDictionaryDto, IDictionaryCreateDto>
    implements IDictionaryRepository {
    protected apiService: IDictionaryApiService;

    constructor(service: IDictionaryApiService) {
        super(service);
        this.apiService = service;
    }

    getDictionaryFields(collectionName: string): Promise<IDictionaryDto> {
        return this.apiService.getDictionaryFields(collectionName);
    }

    getDictionaryItem(
        collectionName: string,
        primaryKey: object,
    ): Promise<IDictionaryItemDto> {
        return this.apiService.getDictionaryItem(collectionName, primaryKey);
    }

    updateDictionaryItem(
        data: IDictionaryItemRequestDto,
    ): Promise<IDictionaryItemDto> {
        return this.apiService.updateDictionaryItem(data);
    }

    createDictionaryItem(
        data: IDictionaryItemRequestDto,
    ): Promise<IDictionaryItemDto> {
        return this.apiService.createDictionaryItem(data);
    }

    deleteDictionaryItem(
        data: IDictionaryItemRequestDto[],
    ): Promise<ObjectId[]> {
        return this.apiService.deleteDictionaryItem(data);
    }

    toEntities(data: IDictionaryDto[]): Dictionary[] {
        return data.map((post: IDictionaryDto) => this.toEntity(post));
    }

    toEntity(data: IDictionaryDto): Dictionary {
        return EntityMapper.toDomainEntity(Dictionary, data);
    }
}
