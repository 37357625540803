import { CommandHandlerUtils } from "./handlers/CommandHandlerUtils";
import { ICommand, ICommandController } from "@mrs/webclient-shared-ui-lib";

class CommandControllerClass implements ICommandController {
    constructor() {
        CommandHandlerUtils.init();
    }

    execute = async (command: ICommand) => {
        const handler = CommandHandlerUtils.getHandler(command);
        if (!!handler) {
            await handler.execute(command.params);
        }
    };
}

export const CommandController = new CommandControllerClass();
