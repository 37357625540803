import { Hook } from "../../hook/model/Hook";
import { ObjectId } from "@mrs/webclient-shared-ui-lib";
import { dto } from "@mrs/platform-configuration-webclient";

export class ViewHook extends Hook {
    private readonly _view: ObjectId;

    constructor(dto: dto.hook.ViewHook) {
        super(dto);
        this._view = dto.view;
    }

    get view(): ObjectId {
        return this._view;
    }
}
