import React from "react";
import { ICreateReportDialogPresenter } from "./ICreateReportDialogPresenter";
import { computed, observable, toJS } from "mobx";
import cloneDeep from "lodash-es/cloneDeep";
import assign from "lodash-es/assign";
import { ReportTemplate } from "../../../../../core/context/reportTemplate/model/ReportTemplate";
import { FormInstance } from "../../../../../core/context/formInstance/model/FormInstance";
import { Form } from "../../../../../core/context/form/model/Form";
import { CurrentUser } from "../../../../../core/context/user/currentUser/CurrentUser";
import { lazyInject } from "../../../../../core/AppDiContainer";
import { FormInstanceDiType } from "../../../../../core/context/formInstance/diType";
import { IFormInstanceService } from "../../../../../core/context/formInstance/service/IFormInstanceService";
import { Notify } from "@utils/notify/Notify";
import { i18next } from "../../../../../../lib/translator";
import { ReportTemplateService } from "../../../../../core/context/reportTemplate/service/ReportTemplateService";
import { ConfigurationAccess } from "../../../../../core/context/configuration/ConfigurationAccess";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";
import {
    AnalyticsEventAction,
    AnalyticsEventComponent,
    AnalyticsEventLocation,
    AnalyticsEventParent,
    AnalyticsEventType,
    IAnalyticsEvent,
    IUserDTO,
} from "@mrs/webclient-shared-ui-lib";
import { IGenerateReportParams } from "../../../../../core/context/reportTemplate/model/IGenerateReportParams";
import { Broadcast } from "../../../../../infrastructure/broadcast/broadcast";
import { ReportEvents } from "../../../../../core/context/report/ReportEvents";

export class CreateReportDialogPresenter
    implements ICreateReportDialogPresenter {
    @observable private _currentTemplate: ReportTemplate | null = null;
    @observable private _formInstance: FormInstance | null = null;
    @observable private _form: Form | null = null;

    @lazyInject(FormInstanceDiType.IFormInstanceService)
    private readonly service: IFormInstanceService;
    private _baseForm: Form | null = null;

    @computed
    public get currentTemplate(): ReportTemplate | null {
        return this._currentTemplate;
    }

    @computed
    public get formInstance(): FormInstance | null {
        return toJS(this._formInstance);
    }

    @computed
    public get form(): Form | null {
        return toJS(this._form);
    }

    setCurrentTemplate = (value: ReportTemplate | null) => {
        this._currentTemplate = value;
    };

    onChangeTemplate = async (
        value: ReportTemplate | null,
        params: Record<string, any>,
    ) => {
        this.setCurrentTemplate(value);
        if (value !== null) {
            await this.requestCreatedFormInstance(
                { formId: value.formId },
                { template: cloneDeep(value.toDTO()), ...params },
            );

            this.logEvent({
                component: AnalyticsEventComponent.Template,
                action: AnalyticsEventAction.Click,
                params: { target: value.templateName },
            });
        } else {
            this._formInstance = null;
            this._form = null;
            this._baseForm = null;
        }
    };

    logEvent = (
        data: Omit<IAnalyticsEvent, "type" | "location" | "parent">,
    ) => {
        AnalyticsService.logEvent({
            type: AnalyticsEventType.System,
            location: AnalyticsEventLocation.ViewManager,
            parent: AnalyticsEventParent.DocCreation,
            ...data,
        });
    };

    onSubmitForm = async (
        reportParams: Record<string, any>,
        onClose: () => void,
    ) => {
        try {
            if (this.currentTemplate && this.formInstance) {
                this.formInstance.canSave();
                const formInstanceDTO = this.formInstance.onSave();
                const formData = formInstanceDTO.data;
                await this.generateReport({
                    docTemplateName: this.currentTemplate.name,
                    templateUrl: (formData as any).templateUrl,
                    formData: formData,
                    filters: {
                        ...reportParams,
                        currentUser: CurrentUser.getDtoWithParsedToken(true),
                    },
                });
                onClose();
            }
        } catch (e) {
            Notify.error({
                message: i18next.t(
                    "common:reportTemplateDialog.error",
                ) as string,
            });
        }
    };

    onChangeFormData = (data: object) => {
        if (!this.formInstance && !this._baseForm) return;
        try {
            this._formInstance = assign(cloneDeep(this.formInstance), { data });
            // @ts-ignore
            this.updateForm(this.formInstance, this._baseForm);
        } catch (e) {
            const message =
                (e as any).message ||
                i18next.t("common:formInstance.canNotUpdate");
            Notify.error({ message });
        }
    };

    private generateReport = async (data: IGenerateReportParams) => {
        const reportId = await ReportTemplateService.generateReport(data);
        Notify.info({
            message: i18next.t(
                "common:reportTemplateDialog.loadingDocument",
            ) as string,
        });
        Broadcast.on(
            ReportEvents.onUpdated,
            (event: any) => {
                if (event.id === reportId) {
                    this.onUpdateReport(event);
                }
            },
            null,
        );
    };

    private onUpdateReport = (event: any): void => {
        if (event.status === "SUCCESS") {
            Broadcast.off(ReportEvents.onUpdated, this.onUpdateReport, null);
            Notify.success({
                message: i18next.t(
                    "common:reportTemplateDialog.success",
                ) as string,
                data: {
                    description: event.fileTitle,
                    duration: Infinity,
                    action: {
                        label: i18next.t("common:common.download"),
                        onClick: () =>
                            ((window as Window).location = event.docUrl),
                    },
                },
            });
        }
        if (event.status === "ERROR") {
            Broadcast.off(ReportEvents.onUpdated, this.onUpdateReport, null);
            Notify.error({
                message: i18next.t(
                    "common:reportTemplateDialog.error",
                ) as string,
                data: { description: event.fileTitle },
            });
        }
    };

    private requestCreatedFormInstance = async (
        params: object,
        initData: object,
    ): Promise<void> => {
        this._formInstance = this.service.createEntity(params, initData);
        this._baseForm =
            (await ConfigurationAccess.getForm(this._formInstance.formId)) ||
            null;
        this._baseForm && this.updateForm(this._formInstance, this._baseForm);
    };

    private updateForm = (formInstance: FormInstance, form: Form) => {
        const viewUpdateHook = formInstance.viewUpdate;
        this._form = viewUpdateHook
            ? viewUpdateHook({
                  item: formInstance.toDTO(),
                  user: cloneDeep(this.currentUser),
                  form: cloneDeep(form),
                  isCreation: true,
              })
            : form;
    };

    private get currentUser(): IUserDTO | null {
        return CurrentUser.getDtoWithParsedToken();
    }
}
