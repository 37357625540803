import { dto } from "@mrs/platform-configuration-webclient";
import { Identifiable } from "../../../sharedKernel/model/Identifiable";

export class Hook extends Identifiable {
    private readonly _function: string;

    constructor(dto: dto.hook.Hook) {
        super(dto);
        this._function = dto.function;
    }

    get function(): string {
        return this._function;
    }

    get hook() {
        return new Function(`return (${this._function})`)();
    }
}
