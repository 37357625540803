import React, { useCallback, useRef, useState } from "react";
import { ICustomComponent } from "@ui/components/shared/CustomComponent/interfaces/ICustomComponent";
import { ButtonWithLabel, JsonDialog } from "@mrs/webclient-shared-ui-lib";
import { useTranslation } from "react-i18next";
import { DEFAULT_NAMESPACE } from "@lib/translator/i18next";

interface IJsonComponentProps extends ICustomComponent {}

export const JsonComponent = (props: IJsonComponentProps) => {
    const {
        required,
        label,
        disabled,
        value,
        onChange,
        id,
        hidden,
        readonly,
    } = props;

    const { t } = useTranslation(DEFAULT_NAMESPACE);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const jsonRef = useRef<object>(value);

    const onChangeJson = useCallback((json: object) => {
        jsonRef.current = json;
    }, []);

    const onOpenDialog = useCallback(() => setIsOpen(true), []);

    const onCloseDialog = useCallback(() => setIsOpen(false), []);

    const onApplyDialog = useCallback(() => {
        onCloseDialog();
        onChange && onChange(id, jsonRef.current ?? null);
    }, [id, jsonRef.current]);

    return !hidden ? (
        <>
            <ButtonWithLabel
                label={label}
                buttonTitle={t("common.edit")}
                required={required}
                disabled={disabled || readonly}
                onClick={onOpenDialog}
            />
            {isOpen && (
                <JsonDialog
                    isOpen={isOpen}
                    title={label}
                    onClose={onCloseDialog}
                    onApply={onApplyDialog}
                    onChangeJson={onChangeJson}
                    jsonObject={value}
                />
            )}
        </>
    ) : null;
};
