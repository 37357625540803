import {
    CommandAlgorithmAction,
    CommandType,
    ICommand,
    IConditionCommandParams,
} from "@mrs/webclient-shared-ui-lib";
import { ICommandHandler } from "../ICommandHandler";
import { CommandController } from "../../CommandController";

export class AlgorithmConditionCommand
    implements ICommandHandler<IConditionCommandParams> {
    get type() {
        return CommandType.Algorithm;
    }

    get action() {
        return CommandAlgorithmAction.Condition;
    }

    execute = async (params: IConditionCommandParams) => {
        let resultCommand: ICommand | undefined;

        try {
            await CommandController.execute(params.condition);
            resultCommand = params?.onSuccess;
        } catch (e) {
            resultCommand = params?.onError;
        }

        if (!!resultCommand) {
            await CommandController.execute(resultCommand);
        }
    };
}
