import {
    CommandNotificationAction,
    CommandType,
    TProperty,
} from "@mrs/webclient-shared-ui-lib";
import { ICommandHandler } from "../ICommandHandler";
import { Notify } from "@utils/notify/Notify";

export class NotificationShowInfoCommand implements ICommandHandler {
    get type() {
        return CommandType.Notification;
    }

    get action() {
        return CommandNotificationAction.ShowInfo;
    }

    execute = async (params: TProperty) => {
        const { message } = params;
        if (!!message) {
            Notify.info({ message });
        }
    };
}
