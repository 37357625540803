import { ObjectType } from "../../core/ObjectType";
import { PathPart } from "./PathPart";
import { ObjectId } from "@mrs/webclient-shared-ui-lib";

const CHUNK_SEPARATOR = "/";
const CHUNK_PARTS_SEPARATOR = ":";

interface IPathChunk {
    [index: number]: string | null;
}

const EMPTY_PATH_PART: PathPart = new PathPart(null, null);

export class PathUtils {
    static parsePath(path: string): IPathChunk[] {
        const chunks: string[] = path ? path.split(CHUNK_SEPARATOR) : [];
        return chunks
            .filter((chunk: string) => !!chunk)
            .map((chunk: string) => chunk.split(CHUNK_PARTS_SEPARATOR));
    }

    static parsePathToObjects(path: string): PathPart[] {
        const pathChunks: IPathChunk[] = this.parsePath(path);
        return pathChunks.map(
            (pathChunk: IPathChunk) =>
                new PathPart(
                    pathChunk[0],
                    ((
                        pathChunk[1] || ""
                    ).toLowerCase() as unknown) as ObjectType,
                ),
        );
    }

    static parsePathByTypes(path: string): { [property: string]: ObjectId[] } {
        const parts = this.parsePathToObjects(path);
        const splittedByType: { [property: string]: ObjectId[] } = {};
        parts.forEach((pathPart) => {
            if (!pathPart.type || !pathPart.id) return;
            splittedByType[pathPart.type]
                ? splittedByType[pathPart.type].push(pathPart.id)
                : (splittedByType[pathPart.type] = [pathPart.id]);
        });
        return splittedByType;
    }

    static getLastPathChunk(path: string): PathPart {
        return this.parsePathToObjects(path).pop() || EMPTY_PATH_PART;
    }

    static getLastIdsByTypes(path: string) {
        const types = new Map();
        const chunks: PathPart[] = this.parsePathToObjects(path);
        chunks.forEach((chunk: PathPart) => {
            chunk && types.set(chunk.type, chunk.id);
        });
        return types;
    }

    static getLastIdByType(
        path: string,
        type: ObjectType,
    ): ObjectId | undefined {
        const typesMap = this.getLastIdsByTypes(path);
        return typesMap.get(type);
    }

    static getFirstByType(path: string, type: string): ObjectId | null {
        const pathChunks: PathPart[] = this.parsePathToObjects(path);
        const chunk: PathPart = findChunkByType(pathChunks, type);
        return chunk.id;
    }

    static isContainPath(path: string, searchPath: string): boolean {
        return path ? path.indexOf(searchPath) === 0 : false;
    }

    static getQueryStringParams(query: string) {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                  .split("&")
                  .reduce((params, param) => {
                      const [key, value] = param.split("=");
                      // @ts-ignore
                      params[key] = value
                          ? decodeURIComponent(value.replace(/\+/g, " "))
                          : "";
                      return params;
                  }, {})
            : {};
    }

    static setKey(path: string, key: string, value: string) {
        let newPath = path;
        const index = newPath.indexOf(key);
        if (index > -1) {
            newPath = newPath.substring(0, index);
        }

        const result = `${newPath}/${key}=${value}`;
        return result.replace(/\/\//g, "/");
    }

    static removeKey(path: string, key: string) {
        return path
            .split(CHUNK_SEPARATOR)
            .filter((el) => !el.includes(key))
            .join(CHUNK_SEPARATOR);
    }
}

function splitToChunks(path: string, separator: string): IPathChunk[] {
    return path ? path.split(separator) : [];
}

function findChunkByType(chunks: PathPart[], type: string): PathPart {
    return (
        chunks.find((pathChunk: PathPart) => pathChunk.type === type) ||
        EMPTY_PATH_PART
    );
}
