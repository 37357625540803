import { DiContainer } from "../../AppDiContainer";
import { FormInstanceEvents } from "./FormInstanceEvents";
import { EventType } from "@mrs/webclient-shared-ui-lib";
import { ObjectType } from "../../ObjectType";
import { EventsBroker } from "../events/EventsBroker";
import { IRestService } from "../../../infrastructure/http/IRestService";
import { ContainerModule } from "inversify";
import { FormInstanceApiService } from "./apiService/impl/FormInstanceApiService";
import { FormInstanceRepository } from "./repository/Impl/FormInstanceRepository";
import { FormInstanceService } from "./service/impl/FormInstanceService";
import { IFormInstanceService } from "./service/IFormInstanceService";
import { FormInstanceDiType } from "./diType";
import { IFormInstanceDialogPresenter } from "@ui/components/formInstance/FormInstanceDialog/presenter/IFormInstanceDialogPresenter";
import { FormInstanceDialogPresenter } from "@ui/components/formInstance/FormInstanceDialog/presenter/FormInstanceDialogPresenter";
import { IAddFormInstanceDialogPresenter } from "@ui/components/formInstance/AddFormInstanceDialog/presenter/IAddFormInstanceDialogPresenter";
import { AddFormInstanceDialogPresenter } from "@ui/components/formInstance/AddFormInstanceDialog/presenter/AddFormInstanceDialogPresenter";

export class FormInstanceContext {
    start(restService: IRestService): void {
        const formInstanceModule = new ContainerModule((bind) => {
            const resourceName = "form-instances";
            const apiService = new FormInstanceApiService(
                restService,
                resourceName,
            );
            const repository = new FormInstanceRepository(apiService);
            const service = new FormInstanceService(repository);

            bind<IFormInstanceService>(
                FormInstanceDiType.IFormInstanceService,
            ).toConstantValue(service);

            bind<IFormInstanceDialogPresenter>(
                FormInstanceDiType.FormInstanceDialogPresenter,
            )
                .to(FormInstanceDialogPresenter)
                .inTransientScope();

            bind<IAddFormInstanceDialogPresenter>(
                FormInstanceDiType.AddFormInstanceDialogPresenter,
            )
                .to(AddFormInstanceDialogPresenter)
                .inTransientScope();
        });
        DiContainer.load(formInstanceModule);

        const eventNamesMap: Map<EventType, string> = new Map();
        eventNamesMap.set(EventType.CREATE, FormInstanceEvents.onCreated);
        eventNamesMap.set(EventType.UPDATE, FormInstanceEvents.onUpdated);
        eventNamesMap.set(EventType.DELETE, FormInstanceEvents.onRemove);
        EventsBroker.setEventNames(ObjectType.FORM_INSTANCE, eventNamesMap);
    }
}
