import { MRSIcon, ObjectId } from "@mrs/webclient-shared-ui-lib";
import { dto } from "@mrs/platform-configuration-client";
import { Identifiable } from "../../../sharedKernel/model/Identifiable";

export class MenuItem extends Identifiable {
    private readonly _viewId: ObjectId;
    private readonly _icon: MRSIcon;
    private readonly _title: string;
    private readonly _order: number;
    private readonly _isSystem: boolean;

    constructor(dto: dto.menu.MenuItem) {
        super(dto);
        this._viewId = dto.viewId;
        this._icon = dto.icon.code as MRSIcon;
        this._title = dto.title;
        this._order = dto.order;
        this._isSystem = dto.isSystem;
    }

    get title(): string {
        return this._title;
    }

    get viewId(): ObjectId {
        return this._viewId;
    }

    get icon(): MRSIcon {
        return this._icon;
    }

    get order(): number {
        return this._order;
    }

    get isSystem(): boolean {
        return this._isSystem;
    }
}
