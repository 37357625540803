import { Hook } from "../../hook/model/Hook";
import { ObjectId, HookType } from "@mrs/webclient-shared-ui-lib";
import { dto } from "@mrs/platform-configuration-webclient";

export class FormHook extends Hook {
    private readonly _title: string;
    private readonly _hookType: HookType;
    private readonly _form: ObjectId;

    constructor(dto: dto.hook.FormHook) {
        super(dto);
        this._title = dto.title;
        this._hookType = dto.hookType.code as HookType;
        this._form = dto.form;
    }

    get title(): string {
        return this._title;
    }

    get hookType(): HookType {
        return this._hookType;
    }

    get form(): ObjectId {
        return this._form;
    }
}
