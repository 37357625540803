import { ICommand } from "@mrs/webclient-shared-ui-lib";
import { ICommandHandler } from "./ICommandHandler";
import { AlgorithmConditionCommand } from "./algorithm/AlgorithmConditionCommand";
import { AlgorithmLinearCommand } from "./algorithm/AlgorithmLinearCommand";
import { FormCloseCommand } from "./form/FormCloseCommand";
import { FormInstanceChangeCommand } from "./formInstance/FormInstanceChangeCommand";
import { FormInstanceCreateCommand } from "./formInstance/FormInstanceCreateCommand";
import { FormInstanceDeleteCommand } from "./formInstance/FormInstanceDeleteCommand";
import { NotificationShowErrorCommand } from "./notification/NotificationShowErrorCommand";
import { NotificationShowInfoCommand } from "./notification/NotificationShowInfoCommand";
import { NotificationShowSuccessCommand } from "./notification/NotificationShowSuccessCommand";
import { DictionaryChangeCommand } from "./dictionary/DictionaryChangeCommand";
import { DictionaryCreateCommand } from "./dictionary/DictionaryCreateCommand";
import { DictionaryDeleteCommand } from "./dictionary/DictionaryDeleteCommand";
import { ConfirmationDeleteCommand } from "./confirmation/ConfirmationDeleteCommand";

class CommandHandlerUtilsClass {
    private handlers: Array<ICommandHandler<any>> = [];

    init = () => {
        this.handlers.push(
            new AlgorithmConditionCommand(),
            new AlgorithmLinearCommand(),

            new ConfirmationDeleteCommand(),

            new FormCloseCommand(),

            new FormInstanceChangeCommand(),
            new FormInstanceCreateCommand(),
            new FormInstanceDeleteCommand(),

            new NotificationShowErrorCommand(),
            new NotificationShowInfoCommand(),
            new NotificationShowSuccessCommand(),

            new DictionaryChangeCommand(),
            new DictionaryCreateCommand(),
            new DictionaryDeleteCommand(),
        );
    };

    getHandler = (command: ICommand) => {
        const { type, action } = command;
        return this.handlers.find(
            (value) => value.type === type && value.action === action,
        );
    };
}

export const CommandHandlerUtils = new CommandHandlerUtilsClass();
