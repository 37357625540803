import React from "react";
import { IPageViewManagerPresenter } from "./IPageViewManagerPresenter";
import { computed, observable, toJS } from "mobx";
import { View } from "../../../../../core/context/view/model/View";
import cloneDeep from "lodash-es/cloneDeep";
import debounce from "lodash-es/debounce";
import uniqBy from "lodash-es/uniqBy";
import without from "lodash-es/without";
import { i18next } from "../../../../../../lib/translator";
import { CurrentUser } from "../../../../../core/context/user/currentUser/CurrentUser";
import { ReportTemplate } from "../../../../../core/context/reportTemplate/model/ReportTemplate";
import {
    AnalyticsEventAction,
    AnalyticsEventComponent,
    AnalyticsEventLocation,
    AnalyticsEventParent,
    AnalyticsEventType,
    CURRENT_VARIABLE_NAME,
    FluentUiIconName,
    IAnalyticsEvent,
    ICheckedFilter,
    Icon,
    IIconButtonAction,
    IViewFilter,
    IWithId,
    KeyCode,
    Nullable,
    QueryName,
    VIEW_FILTER_TYPE,
    ViewMode,
    ViewUtils,
} from "@mrs/webclient-shared-ui-lib";
import { WithViewComponentClass } from "@ui/components/managers/base/WithViewComponent";
import { ConfigurationAccess } from "../../../../../core/context/configuration/ConfigurationAccess";
import get from "lodash-es/get";
import sortBy from "lodash-es/sortBy";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";
import { ViewModuleInitializer } from "@ui/components/view/shared/ViewModuleInitializer";
import isString from "lodash-es/isString";

export class PageViewManagerPresenter extends WithViewComponentClass
    implements IPageViewManagerPresenter {
    @observable private _viewTitle: string = "";
    @observable private _searchValue: string = "";
    @observable private _showTitleAction: boolean = false;
    @observable private _disableActions: boolean = false;
    @observable private _disableSidebarFilter: boolean = false;
    @observable private _isOpenFilterDialog: boolean = false;
    @observable private _isSearchEnabled: boolean = false;
    @observable private _filters: ICheckedFilter[] = [];
    @observable private _params: object = {};
    @observable private _currentFilter?: ICheckedFilter;
    @observable private _sidebarFilterIndex: Nullable<number> = null;
    @observable private _isOpenCreateReportDialog: boolean = false;
    @observable private _isFocusField: boolean = false;
    @observable private _reportTemplates: ReportTemplate[] = [];
    @observable private _canCreate: boolean = false;
    @observable private _view: Nullable<View> = null;
    private _variablesByView: Map<string, Record<string, any>> = new Map();

    private searchView = debounce(async (variables: object) => {
        await this._viewComponentPresenter?.searchView(variables);
    }, 300);

    constructor() {
        super();
    }

    @computed
    public get viewTitle(): string {
        return this._viewTitle;
    }

    @computed
    get searchValue(): string {
        return this._searchValue;
    }

    @computed
    public get disableActions(): boolean {
        return this._disableActions;
    }

    @computed
    public get disableSidebarFilter(): boolean {
        return this._disableSidebarFilter;
    }

    @computed
    public get isFocusField(): boolean {
        return this._isFocusField;
    }

    @computed
    get titleAction(): IIconButtonAction {
        return {
            tooltipTitle: "",
            hidden: !this._showTitleAction,
            icon: <Icon iconName={FluentUiIconName.ArrowLeftRegular} />,
            onClick: this.onClickTitleAction,
        };
    }

    @computed
    get toolbarActions(): IIconButtonAction[] {
        return [
            {
                tooltipTitle: i18next.t("common:common.create"),
                hotKey: "C",
                disabled: this._disableActions || !this._canCreate,
                icon: <Icon iconName={FluentUiIconName.AddRegular} />,
                onClick: () => this.onClickAddItem(AnalyticsEventAction.Click),
                uiTestId: "createButton",
            },
            {
                tooltipTitle: i18next.t("common:common.filter"),
                hotKey: "F",
                disabled: this._disableActions,
                hidden: !this._view?.filters.length,
                icon: <Icon iconName={FluentUiIconName.FilterRegular} />,
                onClick: () =>
                    this.handleOpenFilterDialog(AnalyticsEventAction.Click),
                uiTestId: "filterButton",
            },
            {
                tooltipTitle: i18next.t("common:common.generateDocument"),
                hotKey: "Ctrl + D",
                disabled: this._disableActions,
                icon: <Icon iconName={FluentUiIconName.DocumentAddRegular} />,
                onClick: this.onClickGenerateReport,
            },
        ];
    }

    @computed
    get isOpenFilterDialog(): boolean {
        return this._isOpenFilterDialog;
    }

    @computed
    get isSearchEnabled(): boolean {
        return this._isSearchEnabled;
    }

    @computed
    get filters(): ICheckedFilter[] {
        return toJS(this._filters);
    }

    @computed
    get currentFilter() {
        return toJS(this._currentFilter);
    }

    @computed
    get sidebarFilter() {
        return (
            this.filters.find(
                (item: ICheckedFilter) =>
                    item.index === this._sidebarFilterIndex,
            ) || null
        );
    }

    @computed
    get selectedFilters(): ICheckedFilter[] {
        return this.renderFilters.filter(
            (item: ICheckedFilter) => item.checked,
        );
    }

    @computed
    get renderFilters(): ICheckedFilter[] {
        return without(this.filters, this.sidebarFilter) as ICheckedFilter[];
    }

    @computed
    get variables(): Record<string, any> {
        return ViewUtils.toVariables(this.getCheckedFilters(this.filters));
    }

    @computed
    get isOpenCreateReportDialog(): boolean {
        return this._isOpenCreateReportDialog;
    }

    @computed
    get reportTemplates(): ReportTemplate[] {
        return toJS(this._reportTemplates);
    }

    @computed
    get reportParams(): Record<string, any> {
        return { ...this.variables, ...toJS(this._params) };
    }

    init = async (
        viewName: string,
        container: HTMLDivElement,
        variables: Nullable<any>,
        viewItemId?: string,
    ) => {
        try {
            const view = await ConfigurationAccess.getView(viewName);
            if (view) {
                this._view = cloneDeep(view);
                const { filters } = this._view;
                this._viewTitle = this._view.title;
                const initializer = new ViewModuleInitializer(
                    view,
                    view.viewType,
                );
                await initializer.init();
                const { presenter, queries, initView } = initializer;
                this._viewComponentPresenter = presenter;

                variables && this._variablesByView.set(viewName, variables);
                const filterValues =
                    variables || this._variablesByView.get(viewName);

                this._filters = filters
                    ? this.toCheckedFilters(filters, filterValues)
                    : [];
                this._isSearchEnabled = !!get(queries, QueryName.Search);
                const filtersInSidebar = this.getFiltersInSidebar(this.filters);
                if (!!filtersInSidebar.length) {
                    this._sidebarFilterIndex = filtersInSidebar[0].index;
                }
                this.setCurrentFilterValue();

                const params = {
                    multiple: true,
                    viewItemId,
                    canShowActions: true,
                    scrollableTarget: container,
                    hotKeyMethod: this.handleKeyDown,
                    onClickReport: this.onClickReport,
                    mode: ViewMode.SWITCHABLE,
                    onSelect: this.onSelect,
                    setDisableActions: this.setDisableActions,
                    setTitle: this.setTitle,
                };
                await initView(params, filterValues);
            }
            await this.requestCanCreateHook();
            await this.requestReportTemplates();
        } catch (e) {
            console.error("Error on getting view: ", e);
        }
    };

    unmount = () => {
        this._viewComponentPresenter?.clearView();
        this._viewComponentPresenter = null;
        this._sidebarFilterIndex = null;
        this._filters = [];
        this._searchValue = "";
        this._canCreate = false;
        this._showTitleAction = false;
        this.setDisableActions(false);
    };

    onChangeSearchValue = async (value: string) => {
        this._searchValue = value;
        const variables = ViewUtils.toVariablesSearchString(value);
        await this.searchView(variables);
    };

    closeFilterDialog = () => {
        this._isOpenFilterDialog = false;
    };

    applyFilters = async (value: ICheckedFilter[]) => {
        const filters = !!this.sidebarFilter
            ? [...value, this.sidebarFilter]
            : value;
        this._filters = uniqBy(filters, "index");
        this.logEvent({
            component: AnalyticsEventComponent.Apply,
            action: AnalyticsEventAction.Click,
            parent: AnalyticsEventParent.Filter,
            params: {
                filters: this.filters,
            },
        });
        await this.updateView();
    };

    setDisabledHotKeyMethods = (value: boolean) => {
        this._viewComponentPresenter?.setDisabledHotKeyMethods(value);
    };

    openCurrentFilter = (item: ICheckedFilter) => {
        this._currentFilter = item;
        this.openFilterDialog();
    };

    onClearFilter = async (item: ICheckedFilter) => {
        this.clearFilterValue(item);
        await this.updateView();
    };

    onClearAllFilters = async () => {
        this.selectedFilters.forEach(this.clearFilterValue);
        await this.updateView();
    };

    onChangeFilter = async (value: ICheckedFilter) => {
        this.setFilter(value);
        await this.updateView();
    };

    enableFocusField = () => {
        this._isFocusField = true;
    };

    disableFocusField = () => {
        this._isFocusField = false;
    };

    generateReport = () => {
        this._isOpenCreateReportDialog = true;
    };

    closeCreateReportDialog = () => {
        this._isOpenCreateReportDialog = false;
        this._params = {};
    };

    private updateView = debounce(async () => {
        this.setCurrentFilterValue();
        this._variablesByView.set(this._view?.name || "", this.variables);
        await this._viewComponentPresenter?.updateView(this.variables);
    }, 300);

    private setCurrentFilterValue = () => {
        const currentFilter = this.filters.find(
            (filter) => filter.value.params[0].name === CURRENT_VARIABLE_NAME,
        );
        if (currentFilter?.checked) {
            this._viewComponentPresenter?.setCurrentFilterValue(
                currentFilter.value.params[0]?.value || null,
            );
        }
    };

    private onSelect = (els: IWithId[], isPositiveSelection: boolean) => {
        this._params = {
            isPositiveSelection,
            ids: els.length ? els.map((el) => el.id) : undefined,
        };
    };

    private handleKeyDown = (event: KeyboardEvent) => {
        if (this.disableActions) return;

        const code = event.code;
        const ctrlKey = event.ctrlKey;

        if (code === KeyCode.KeyF && !ctrlKey) {
            event.preventDefault();
            this.handleOpenFilterDialog(AnalyticsEventAction.HotKey);
        }
        if (
            code === KeyCode.KeyC &&
            !ctrlKey &&
            !(this._disableActions || !this._canCreate)
        ) {
            event.preventDefault();
            this.onClickAddItem(AnalyticsEventAction.HotKey);
        }
    };

    private onClickAddItem = (action: AnalyticsEventAction) => {
        if (!this._view?.forCreate) return;
        this._viewComponentPresenter?.onAddItem(this._view.forCreate);
        this.logEvent({
            action,
            params: { target: "create" },
            component: AnalyticsEventComponent.Action,
        });
    };

    private handleOpenFilterDialog = (action: AnalyticsEventAction) => {
        this.openFilterDialog();
        this.logEvent({
            action,
            params: { target: "filter" },
            component: AnalyticsEventComponent.Action,
        });
    };

    private onClickGenerateReport = () => {
        this.generateReport();
        this.logEvent({
            action: AnalyticsEventAction.Click,
            params: { target: "create_doc" },
            component: AnalyticsEventComponent.Action,
            parent: AnalyticsEventParent.ActionBarMenu,
        });
    };

    private logEvent = ({
        params,
        ...other
    }: Omit<IAnalyticsEvent, "type" | "location">) => {
        AnalyticsService.logEvent({
            ...other,
            type: AnalyticsEventType.System,
            location: AnalyticsEventLocation.ViewManager,
            params: {
                view: this._view?.name,
                ...params,
            },
        });
    };

    private clearFilterValue = (item: ICheckedFilter) => {
        if (!item.checked) return;
        const data: ICheckedFilter = { ...item, checked: false };
        const filterValue = data.value;
        if (filterValue.type !== VIEW_FILTER_TYPE.SWITCH) {
            filterValue.params[0].value = undefined;
        }
        this.setFilter(data);
    };

    private setFilter = (value: ICheckedFilter) => {
        const index = this.filters.findIndex(
            (filter: ICheckedFilter) => filter.index === value.index,
        );
        if (index > -1) {
            this._filters[index] = value;
        }
    };

    private onClickReport = (params: object) => {
        this._params = params;
        this.generateReport();
    };

    private openFilterDialog = () => {
        this._viewComponentPresenter?.clearSelected();
        this._isOpenFilterDialog = true;
    };

    private requestCanCreateHook = async () => {
        const forCreate = this._view?.forCreate;
        const canCreateHook = ConfigurationAccess.getCanCreateHooksSync(
            forCreate?.formId,
        );
        this._canCreate = !!canCreateHook
            ? canCreateHook({ user: CurrentUser.getDtoWithParsedToken() })
            : !!forCreate;
    };

    private toCheckedFilters = (
        filters: IViewFilter[],
        variables: Nullable<Record<string, any>>,
    ): ICheckedFilter[] => {
        return filters.map((filter, i) => {
            const result = {
                index: i,
                checked: false,
                value: filter,
            };
            variables &&
                filter.params.forEach((param, i) => {
                    const valueByName = get(variables, param.name);
                    if (valueByName) {
                        result.checked = true;
                        if (Array.isArray(valueByName)) {
                            let data = valueByName;
                            if (isString(valueByName[0])) {
                                data = valueByName.map((id) => ({ id }));
                            }
                            result.value.params[i].value = data;
                        } else {
                            result.value.params[i].value = valueByName;
                        }
                    }
                });
            return result;
        });
    };

    private getFiltersInSidebar = (
        filters: ICheckedFilter[],
    ): ICheckedFilter[] => {
        return filters.filter(
            (filter: ICheckedFilter) => !!filter.value.inSidebar,
        );
    };

    private requestReportTemplates = async () => {
        const templates = await ConfigurationAccess.getReportTemplates(
            `view/${this._view?.name}`,
        );
        this._reportTemplates = sortBy(templates, ["templateName"]);
    };

    private getCheckedFilters = (items: ICheckedFilter[]): IViewFilter[] => {
        const filters: IViewFilter[] = [];
        items.length &&
            items.forEach((filter) => {
                if (filter.checked) filters.push(filter.value);
            });
        return filters;
    };

    private setDisableActions = (value: boolean) => {
        this._disableActions = value;
    };

    private setTitle = (value: string | null): void => {
        if (value) {
            this._viewTitle = value;
            this._showTitleAction = true;
            this._disableSidebarFilter = true;
            this.setDisableActions(true);
        } else {
            this.setDefaultTitle();
        }
    };

    private setDefaultTitle = (): void => {
        this._viewTitle = this._view?.title || "";
        this._showTitleAction = false;
        this._disableSidebarFilter = false;
        this.setDisableActions(false);
    };

    private onClickTitleAction = (): void => {
        this.setDefaultTitle();
        this._viewComponentPresenter?.onClickBack();
    };
}
