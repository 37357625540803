import {
    CommandBaseAction,
    CommandType,
    TProperty,
} from "@mrs/webclient-shared-ui-lib";
import { ICommandHandler } from "../ICommandHandler";
import { lazyInject } from "../../../../core/AppDiContainer";
import { FormInstanceDiType } from "../../../../core/context/formInstance/diType";
import { IFormInstanceService } from "../../../../core/context/formInstance/service/IFormInstanceService";

export class FormInstanceCreateCommand implements ICommandHandler {
    @lazyInject(FormInstanceDiType.IFormInstanceService)
    private readonly service: IFormInstanceService;

    get type() {
        return CommandType.FormInstance;
    }

    get action() {
        return CommandBaseAction.Create;
    }

    execute = async (params: TProperty) => {
        const { creationData, initData = {}, data } = params;
        if (!creationData || !data) return;

        const entity = this.service.createEntity(creationData, initData);
        entity.data = Object.assign(entity.data, data);

        await this.service.createFormInstance(entity);
    };
}
