import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { PageViewManagerPresenter } from "./presenter/PageViewManagerPresenter";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {
    DIVIDER_HEIGHT,
    HEADER_HEIGHT,
    SEARCH_HEIGHT,
} from "@ui/theme/baseTheme/BaseTheme";
import { ADDITIONAL_COLOR } from "@ui/theme/baseTheme/color/AdditionalColor";
import { ViewFilterSidebar } from "../../view/filters/ViewFilterSidebar/ViewFilterSidebar";
import {
    CHIPS_PANEL_HEIGHT,
    ViewChipFilters,
} from "../../view/filters/ViewChipFilters/ViewChipFilters";
import { FilterDialog } from "../../view/filters/FilterDialog/FilterDialog";
import { CreateReportDialog } from "../../shared/CreateReportDialog/CreateReportDialog";
import {
    IIconButtonAction,
    IconButtonAction,
    Icon,
    FluentUiIconName,
    ViewUtils,
} from "@mrs/webclient-shared-ui-lib";
import { ViewSearch } from "@ui/components/shared/ViewSearch/ViewSearch";
import { useAppMenu } from "@ui/components/shared/AppMainLayout/hook/AppMenu";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { QueryBuilder } from "../../../../infrastructure/http/QueryBuilder";

interface IPageViewManagerProps {
    viewName: string;
    viewItemId?: string;
}

export const PageViewManager = observer((props: IPageViewManagerProps) => {
    const { viewName, viewItemId } = props;
    const presenter = useMemo(() => new PageViewManagerPresenter(), []);
    const { open, onDrawerOpen } = useAppMenu();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        viewTitle,
        disableSidebarFilter,
        titleAction,
        toolbarActions,
        isOpenFilterDialog,
        isOpenCreateReportDialog,
        isSearchEnabled,
        isFocusField,
        renderFilters,
        reportParams,
        selectedFilters,
        currentFilter,
        sidebarFilter,
        variables,
        reportTemplates,
        closeFilterDialog,
        applyFilters,
        openCurrentFilter,
        onClearFilter,
        onClearAllFilters,
        onChangeFilter,
        closeCreateReportDialog,
    } = presenter;
    const ref = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const filters = searchParams.get("filters");
        const filterSearchParam = filters ? JSON.parse(filters) : null;
        viewName &&
            ref.current &&
            presenter.init(
                viewName,
                ref.current,
                filterSearchParam,
                viewItemId,
            );
        return () => {
            presenter.unmount();
        };
    }, [viewName]);

    useEffect(() => {
        const search = Object.keys(variables).length
            ? QueryBuilder.addParams(
                  "",
                  ViewUtils.getFilterQueryParam(variables),
              )
            : "";
        const path = {
            pathname: window.location.pathname,
            search,
        };
        navigate(path);
    }, [variables]);

    useEffect(() => {
        const value =
            isOpenFilterDialog || isOpenCreateReportDialog || isFocusField;
        presenter.setDisabledHotKeyMethods(value);
    }, [isOpenFilterDialog, isOpenCreateReportDialog, isFocusField]);

    const viewHeight = useMemo(() => {
        let result = HEADER_HEIGHT + DIVIDER_HEIGHT;
        if (isSearchEnabled) {
            result += SEARCH_HEIGHT + DIVIDER_HEIGHT;
        }
        if (!!selectedFilters.length) {
            result += CHIPS_PANEL_HEIGHT + DIVIDER_HEIGHT;
        }
        return `calc(100% - ${result}px)`;
    }, [isSearchEnabled, selectedFilters]);

    const renderMenu = useCallback(
        () => (
            <IconButtonAction
                key={"menu"}
                item={{
                    tooltipTitle: t("common:common.navigation.show"),
                    hotKey: "Ctrl + \\",
                    icon: (
                        <Icon iconName={FluentUiIconName.NavigationRegular} />
                    ),
                    onClick: onDrawerOpen,
                }}
                sx={styles.menuIcon}
            />
        ),
        [],
    );

    return (
        <Box
            display={"flex"}
            height={"100vh"}
            sx={{
                transition: (theme) =>
                    theme.transitions.create(["margin", "width"], {
                        easing: open
                            ? theme.transitions.easing.easeOut
                            : theme.transitions.easing.sharp,
                        duration: open
                            ? theme.transitions.duration.enteringScreen
                            : theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {!!sidebarFilter && (
                <ViewFilterSidebar
                    viewName={viewName}
                    filter={sidebarFilter}
                    parentName={viewTitle}
                    menu={!open && sidebarFilter ? renderMenu() : undefined}
                    disabled={disableSidebarFilter}
                    onChange={onChangeFilter}
                    onFocus={presenter.enableFocusField}
                    onBlur={presenter.disableFocusField}
                />
            )}
            <Box sx={styles.container}>
                <Box sx={styles.topPanel}>
                    <Box sx={styles.titleContainer}>
                        {!open && !sidebarFilter && renderMenu()}
                        {!titleAction.hidden && (
                            <IconButtonAction
                                key={`tooltip - ${titleAction}`}
                                item={titleAction}
                                sx={{
                                    m: (theme) => theme.spacing(0, 2, 0, 0),
                                }}
                            />
                        )}
                        <Typography
                            variant={"subtitle2"}
                            color={"textPrimary"}
                            noWrap
                        >
                            {viewTitle}
                        </Typography>
                    </Box>
                    <Box display={"flex"}>
                        {toolbarActions.map(
                            (item: IIconButtonAction, index: number) =>
                                !item.hidden && (
                                    <IconButtonAction
                                        key={`tooltip - ${index}`}
                                        item={item}
                                    />
                                ),
                        )}
                    </Box>
                </Box>
                <Divider />
                {isSearchEnabled && (
                    <>
                        <ViewSearch
                            searchValue={presenter.searchValue}
                            onChange={presenter.onChangeSearchValue}
                            onFocus={presenter.enableFocusField}
                            onBlur={presenter.disableFocusField}
                        />
                        <Divider />
                    </>
                )}
                {!!selectedFilters.length && (
                    <ViewChipFilters
                        filters={selectedFilters}
                        openCurrentFilter={openCurrentFilter}
                        onClearFilter={onClearFilter}
                        onClearAllFilters={onClearAllFilters}
                    />
                )}
                <Box ref={ref} height={viewHeight} sx={styles.viewContainer}>
                    {presenter.renderView}
                </Box>
            </Box>
            <FilterDialog
                open={isOpenFilterDialog}
                filters={renderFilters}
                selectedFilter={currentFilter}
                onChange={applyFilters}
                onClose={closeFilterDialog}
            />
            {isOpenCreateReportDialog && (
                <CreateReportDialog
                    isOpen={isOpenCreateReportDialog}
                    templates={reportTemplates}
                    reportParams={reportParams}
                    onClose={closeCreateReportDialog}
                />
            )}
        </Box>
    );
});

const styles = {
    container: {
        backgroundColor: ADDITIONAL_COLOR.background,
        width: "100%",
        overflow: "hidden",
    },
    topPanel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: "background.paper",
        pr: 1,
        pl: 2,
        boxSizing: "border-box",
        minHeight: HEADER_HEIGHT,
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        m: (theme: Theme) => theme.spacing(-1, 0),
        whiteSpace: "nowrap",
    },
    viewContainer: {
        width: "100%",
        boxSizing: "border-box",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    menuIcon: {
        m: (theme: Theme) => theme.spacing(0, 2, 0, -0.75),
    },
};
