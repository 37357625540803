import {
    CommandBaseAction,
    CommandType,
    TProperty,
} from "@mrs/webclient-shared-ui-lib";
import { ICommandHandler } from "../ICommandHandler";
import { lazyInject } from "../../../../core/AppDiContainer";
import { FormInstanceDiType } from "../../../../core/context/formInstance/diType";
import { IFormInstanceService } from "../../../../core/context/formInstance/service/IFormInstanceService";
import isEmpty from "lodash-es/isEmpty";

export class FormInstanceChangeCommand implements ICommandHandler {
    @lazyInject(FormInstanceDiType.IFormInstanceService)
    private readonly service: IFormInstanceService;

    get type() {
        return CommandType.FormInstance;
    }

    get action() {
        return CommandBaseAction.Change;
    }

    execute = async (params: TProperty) => {
        const { id, ...changes } = params;
        if (!isEmpty(changes)) {
            await this.service.changeFormInstance(id, changes);
        }
    };
}
