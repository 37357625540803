import {
    CommandAlgorithmAction,
    CommandType,
    ILinearCommandParams,
} from "@mrs/webclient-shared-ui-lib";
import { ICommandHandler } from "../ICommandHandler";
import { CommandController } from "../../CommandController";

export class AlgorithmLinearCommand
    implements ICommandHandler<ILinearCommandParams> {
    get type() {
        return CommandType.Algorithm;
    }

    get action() {
        return CommandAlgorithmAction.Linear;
    }

    execute = async (params: ILinearCommandParams) => {
        for (const command of params.commands) {
            await CommandController.execute(command);
        }
    };
}
