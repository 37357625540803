import { AbstractApplicationService } from "../../../../sharedKernel/service/impl/AbstractApplicationService";
import { Dictionary } from "../../model/Dictionary";
import { IDictionaryRepository } from "../../repository/IDictionaryRepository";
import { IDictionaryService } from "../IDictionaryService";
import { IDictionaryCreateDto } from "../../dto/IDictionaryCreateDto";
import { ObjectType } from "../../../../ObjectType";
import { injectable } from "inversify";
import {
    IDictionaryDto,
    IDictionaryField,
    IDictionaryItemDto,
    IDictionaryItemRequestDto,
    Nullable,
    ObjectId,
} from "@mrs/webclient-shared-ui-lib";
import { Notify } from "@utils/notify/Notify";
import i18next from "@lib/translator/i18next";

@injectable()
export class DictionaryService
    extends AbstractApplicationService<
        Dictionary,
        IDictionaryDto,
        IDictionaryCreateDto,
        IDictionaryRepository
    >
    implements IDictionaryService {
    private _currentCollectionName: Nullable<string> = null;

    constructor(repository: IDictionaryRepository) {
        super(repository, ObjectType.DICTIONARY);
    }

    get currentCollectionName(): Nullable<string> {
        return this._currentCollectionName;
    }

    async getDictionaryFields(
        collectionName: Nullable<string> = this.currentCollectionName,
    ): Promise<IDictionaryField[]> {
        try {
            if (collectionName) {
                const dictionaryDto = await this.repository.getDictionaryFields(
                    collectionName,
                );
                return dictionaryDto?.fields || [];
            }
            return [];
        } catch (e) {
            Notify.error({
                message: i18next.t(
                    "common:dictionary.getFieldsError",
                ) as string,
            });
            return [];
        }
    }

    async getDictionaryItem(
        primaryKey: object,
        collectionName: Nullable<string> = this.currentCollectionName,
    ): Promise<IDictionaryItemDto | null> {
        try {
            if (collectionName && primaryKey) {
                return this.repository.getDictionaryItem(
                    collectionName,
                    primaryKey,
                );
            }
            return null;
        } catch (e) {
            Notify.error({
                message: i18next.t("common:dictionary.getItemError") as string,
            });
            return null;
        }
    }

    setCurrentCollectionName(collectionName: Nullable<string>): void {
        this._currentCollectionName = collectionName;
    }

    async updateDictionaryItem(
        itemId: ObjectId,
        changes: Partial<IDictionaryItemDto>,
        collectionName: Nullable<string> = this.currentCollectionName,
    ): Promise<Nullable<IDictionaryItemDto>> {
        try {
            if (collectionName && itemId) {
                const requestDto = this.createRequestDto(
                    itemId,
                    changes,
                    collectionName,
                );
                return await this.repository.updateDictionaryItem(requestDto);
            }
            return null;
        } catch (e) {
            Notify.error({
                message: i18next.t("common:dictionary.updateError") as string,
            });
            return null;
        }
    }

    async createDictionaryItem(
        dictionaryItemDto: IDictionaryItemDto,
        collectionName: Nullable<string> = this.currentCollectionName,
    ): Promise<Nullable<IDictionaryItemDto>> {
        try {
            if (!collectionName) return null;
            const requestDto = this.createRequestDto(
                undefined,
                dictionaryItemDto,
                collectionName,
            );
            return await this.repository.createDictionaryItem(requestDto);
        } catch (e) {
            Notify.error({
                message: i18next.t("common:dictionary.createError") as string,
            });
            return null;
        }
    }

    async deleteDictionaryItem(
        ids: ObjectId[],
        collectionName: Nullable<string> = this.currentCollectionName,
    ): Promise<ObjectId[]> {
        try {
            const requestDto: IDictionaryItemRequestDto[] = this.createRequestsDto(
                ids,
                undefined,
                collectionName,
            );
            return !!collectionName
                ? await this.repository.deleteDictionaryItem(requestDto)
                : [];
        } catch (e) {
            Notify.error({
                message: i18next.t("common:dictionary.deleteError") as string,
            });
            return [];
        }
    }

    private createRequestsDto(
        ids: ObjectId[],
        payLoad?: Partial<IDictionaryItemDto>,
        collectionName?: Nullable<string>,
    ): IDictionaryItemRequestDto[] {
        try {
            return ids.map((id) =>
                this.createRequestDto(id, payLoad, collectionName),
            );
        } catch (e) {
            return [];
        }
    }

    private createRequestDto(
        id?: ObjectId,
        payLoad?: Partial<IDictionaryItemDto>,
        collectionName?: Nullable<string>,
    ): IDictionaryItemRequestDto {
        return {
            collection_name: collectionName || "",
            key: id ? JSON.parse(decodeURIComponent(id)) : undefined,
            payLoad,
        };
    }
}
