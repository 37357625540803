import { CheckboxesWidget } from "./CustomFormWidget/CheckboxesWidget";
import { RadioWidget } from "./CustomFormWidget/RadioWidget/RadioWidget";
import { DateTimeWidget } from "./CustomFormWidget/DateTimeWidget";
import { DateWidget } from "./CustomFormWidget/DateWidget";
import { SelectWidget } from "./CustomFormWidget/SelectWidget";
import { TextareaWidget } from "./CustomFormWidget/TextareaWidget";
import { TextWidget } from "./CustomFormWidget/TextWidget";
import { ButtonSwitcherWidget } from "@ui/components/shared/CustomForm/components/CustomFormWidget/ButtonSwitcherWidget";
import {
    BaseSchemaType,
    CUSTOM_JSON_FORM_TYPE,
} from "../../../../../core/context/form/dto/IFormDTO";
import { ICustomFormData } from "@ui/components/shared/CustomForm/ICustomForm";

export const getWidgetData = (): ICustomFormData => {
    return {
        [CUSTOM_JSON_FORM_TYPE.DATE]: {
            type: BaseSchemaType.Number,
            fieldName: CUSTOM_JSON_FORM_TYPE.DATE,
            component: DateWidget,
        },
        [CUSTOM_JSON_FORM_TYPE.DATETIME]: {
            type: BaseSchemaType.Number,
            fieldName: CUSTOM_JSON_FORM_TYPE.DATETIME,
            component: DateTimeWidget,
        },
        [CUSTOM_JSON_FORM_TYPE.CHECKBOXES]: {
            type: BaseSchemaType.Array,
            fieldName: CUSTOM_JSON_FORM_TYPE.CHECKBOXES,
            component: CheckboxesWidget,
        },
        [CUSTOM_JSON_FORM_TYPE.RADIO]: {
            type: BaseSchemaType.String,
            fieldName: CUSTOM_JSON_FORM_TYPE.RADIO,
            component: RadioWidget,
        },
        [CUSTOM_JSON_FORM_TYPE.EMAIL]: {
            type: BaseSchemaType.String,
            fieldName: CUSTOM_JSON_FORM_TYPE.EMAIL,
            component: TextWidget,
        },
        [CUSTOM_JSON_FORM_TYPE.TEXT]: {
            type: BaseSchemaType.String,
            fieldName: CUSTOM_JSON_FORM_TYPE.TEXT,
            component: TextWidget,
        },
        [CUSTOM_JSON_FORM_TYPE.NUMBER]: {
            type: BaseSchemaType.Number,
            fieldName: CUSTOM_JSON_FORM_TYPE.TEXT,
            component: TextWidget,
        },
        [CUSTOM_JSON_FORM_TYPE.TEXTAREA]: {
            type: BaseSchemaType.String,
            fieldName: CUSTOM_JSON_FORM_TYPE.TEXTAREA,
            component: TextareaWidget,
        },
        [CUSTOM_JSON_FORM_TYPE.SELECT]: {
            fieldName: CUSTOM_JSON_FORM_TYPE.SELECT,
            component: SelectWidget,
        },
        [CUSTOM_JSON_FORM_TYPE.BUTTON_SWITCHER]: {
            type: BaseSchemaType.String,
            fieldName: CUSTOM_JSON_FORM_TYPE.BUTTON_SWITCHER,
            component: ButtonSwitcherWidget,
        },
    };
};
