import React from "react";
import {
    IFormInstanceDialogParams,
    IFormInstanceDialogPresenter,
} from "./IFormInstanceDialogPresenter";
import { computed, observable, toJS } from "mobx";
import { FormInstanceDialog } from "../FormInstanceDialog";
import { injectable } from "inversify";
import assign from "lodash-es/assign";
import cloneDeep from "lodash-es/cloneDeep";
import isEmpty from "lodash-es/isEmpty";
import assignWith from "lodash-es/assignWith";
import isObject from "lodash-es/isObject";
import { FormInstance } from "../../../../../core/context/formInstance/model/FormInstance";
import { Form } from "../../../../../core/context/form/model/Form";
import { CurrentUser } from "../../../../../core/context/user/currentUser/CurrentUser";
import { i18next } from "../../../../../../lib/translator";
import { Notify } from "@utils/notify/Notify";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { BaseTheme } from "@ui/theme/baseTheme/BaseTheme";
import { createRoot } from "react-dom/client";
import {
    AnalyticsEventAction,
    AnalyticsEventComponent,
    AnalyticsEventLocation,
    AnalyticsEventParent,
    AnalyticsEventType,
    ConfirmDialog,
    FluentUiIconName,
    IAnalyticsEvent,
    Icon,
    IDOMElement,
    IIconButtonAction,
    ObjectId,
    ObjectUtils,
} from "@mrs/webclient-shared-ui-lib";
import { EntityMapper } from "../../../../../core/sharedKernel/mapper/EntityMapper";
import { lazyInject } from "../../../../../core/AppDiContainer";
import { IFormInstanceService } from "../../../../../core/context/formInstance/service/IFormInstanceService";
import { FormInstanceDiType } from "../../../../../core/context/formInstance/diType";
import { Broadcast } from "../../../../../infrastructure/broadcast/broadcast";
import { FormInstanceEvents } from "../../../../../core/context/formInstance/FormInstanceEvents";
import { User } from "../../../../../core/context/user/model/User";
import { ApplicationDiType } from "@ui/diType";
import { IUserCollection } from "../../../../../core/context/user/collection/IUserCollection";
import { ConfigurationAccess } from "../../../../../core/context/configuration/ConfigurationAccess";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";
import { TabType } from "../../../../../core/context/form/dto/TypeDefinition";
import { DialogIdManager } from "@utils/DialogIdManager";

@injectable()
export class FormInstanceDialogPresenter
    implements IFormInstanceDialogPresenter {
    @observable private _isOpen: boolean = false;
    @observable private _formInstance: FormInstance | null = null;
    @observable private _owner: User | null = null;
    @observable private _baseFormInstance: FormInstance | null = null;
    @observable private _form!: Form;
    @observable private _isButtonLoading: boolean = false;
    @observable private _isChangesSaved: boolean = false;

    private popupElement: IDOMElement = {
        element: document.createElement("div"),
    };
    private _baseForm: Form | null = null;
    private _onClose?: () => void;
    @lazyInject(FormInstanceDiType.IFormInstanceService)
    private readonly service: IFormInstanceService;
    @lazyInject(ApplicationDiType.IUserCollection)
    private readonly userCollection: IUserCollection;

    constructor() {
        Broadcast.on(
            [FormInstanceEvents.onUpdated],
            async (event: any) => {
                if (event.id === this.formInstance?.id) {
                    await this.requestFormInstanceData(event.id);
                }
            },
            null,
        );
    }

    @computed
    public get isOpen(): boolean {
        return this._isOpen;
    }

    @computed
    public get formInstance(): FormInstance | null {
        return toJS(this._formInstance);
    }

    @computed
    public get baseFormInstance(): FormInstance | null {
        return toJS(this._baseFormInstance);
    }

    @computed
    public get owner(): User | null {
        return toJS(this._owner);
    }

    @computed
    public get form(): Form {
        return toJS(this._form);
    }

    @computed
    public get isButtonLoading(): boolean {
        return this._isButtonLoading;
    }

    @computed
    public get isChangesSaved(): boolean {
        return this._isChangesSaved;
    }

    @computed
    get toolbarActions(): IIconButtonAction[] {
        return [
            {
                tooltipTitle: i18next.t("common:common.delete"),
                disabled: !this.canDelete(),
                icon: <Icon iconName={FluentUiIconName.DeleteRegular} />,
                onClick: this.onRemoveFormInstance,
            },
        ];
    }

    showModal = async (params: IFormInstanceDialogParams) => {
        const { id, onClose } = params;
        DialogIdManager.add(id);
        this.popupElement.root = createRoot(this.popupElement.element);
        document.body.appendChild(this.popupElement.element);
        await this.requestFormInstanceData(id);
        this._isOpen = true;
        this._onClose = onClose;
        this.renderModal();
    };

    onClose = () => {
        if (!document.body.contains(this.popupElement.element)) return;
        DialogIdManager.remove(this.formInstance?.id);
        this.popupElement.root?.unmount();
        this.popupElement.element.remove();
        this._isOpen = false;
        this._formInstance = null;
        this._baseFormInstance = null;
        this._baseForm = null;
        this._onClose && this._onClose();
    };

    onChangeHistory = (event: PopStateEvent) => {
        const id = DialogIdManager.getFirstOpenDialogId();
        if (id === this.formInstance?.id) {
            this.onClose();
        }
    };

    onChangeData = (value: object) => {
        this.setFormInstance({ data: value });
    };

    onClickSaveChanges = async () => {
        try {
            this._isButtonLoading = true;
            if (!this._baseFormInstance || !this.formInstance) return;
            this.logEvent({
                component: AnalyticsEventComponent.Save,
                action: AnalyticsEventAction.Click,
                params: {
                    base: this._baseFormInstance.toDTO(),
                    updated: this.formInstance.toDTO(),
                },
            });
            await this.service.updateFormInstance(
                this._baseFormInstance,
                this.formInstance,
            );
            this.setIsChangesSaved(true);
        } catch (e) {
            this.setIsChangesSaved(false);
            const message =
                (e as any).message ||
                i18next.t("common:formInstance.updateError");
            Notify.error({ message });
        } finally {
            this._isButtonLoading = false;
        }
    };

    logEvent = (data: Omit<IAnalyticsEvent, "type" | "location">): void => {
        AnalyticsService.logEvent({
            type: AnalyticsEventType.System,
            location: AnalyticsEventLocation.FormDialog,
            ...data,
        });
    };

    setIsChangesSaved = (value: boolean) => {
        this._isChangesSaved = value;
    };

    dialogStateDataToDefault = () => {
        this._isButtonLoading = false;
        this.setIsChangesSaved(false);
        this._formInstance = null;
    };

    hideFormFields = (
        formSchema: Form,
        fieldNames: string[],
        type?: TabType,
    ) => {
        const form = cloneDeep(formSchema);
        const properties = form.scheme.properties;
        for (const key in properties) {
            const hidden = properties[key].hidden;
            const isHidden = hidden || !fieldNames.includes(key);
            properties[key].hidden = isHidden;
            if (!isHidden) {
                properties[key].fullScreen = type
                    ? type === TabType.FullScreen
                    : undefined;
            }
        }
        return form;
    };

    private renderModal = () => {
        this.popupElement.root?.render(
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={BaseTheme}>
                    <FormInstanceDialog presenter={this} />
                </ThemeProvider>
            </StyledEngineProvider>,
        );
    };

    private async getFormInstanceById(id: ObjectId) {
        return this.service.getById(id);
    }

    private async requestFormInstance(id: ObjectId) {
        const formInstance = (await this.getFormInstanceById(id)) || null;
        this.setReceivedFormInstance(formInstance);
        this._baseFormInstance = formInstance;
    }

    private requestFormInstanceData = async (id: ObjectId) => {
        await this.requestFormInstance(id);
        if (!this.formInstance) return;
        if (!this._baseForm) {
            this._baseForm =
                ConfigurationAccess.getForm(this.formInstance.formId) || null;
        }
        this._baseForm &&
            this.onChangeFormInstance(
                this.formInstance,
                (this._baseForm as unknown) as Form,
            );
        const user = this.userCollection.getById(this.formInstance.owner);
        if (user) {
            this._owner = user;
        }
    };

    private setFormInstance = (changes: Partial<FormInstance>): void => {
        if (!this._baseForm) return;
        try {
            if (this.formInstance === null) {
                // @ts-ignore
                this._formInstance = changes;
            } else {
                this._formInstance = assign(
                    cloneDeep(this.formInstance),
                    changes,
                );
                this.onChangeFormInstance(this.formInstance, this._baseForm);
            }
        } catch (e) {
            const message =
                (e as any).message ||
                i18next.t("common:formInstance.canNotUpdate");
            Notify.error({ message });
        }
    };

    private setReceivedFormInstance = (
        formInstance: FormInstance | null,
    ): void => {
        try {
            if (this.formInstance === null) {
                this._formInstance = formInstance;
            } else {
                if (!this._baseFormInstance) return;
                const changes = ObjectUtils.findChanges(
                    EntityMapper.toPlainObject(this.formInstance),
                    EntityMapper.toPlainObject(this._baseFormInstance),
                );
                this._formInstance = isEmpty(changes)
                    ? formInstance
                    : assignWith(
                          cloneDeep(formInstance),
                          changes,
                          this.customizer,
                      );
            }
        } catch (e) {
            const message =
                (e as any).message ||
                i18next.t("common:formInstance.canNotUpdate");
            Notify.error({ message });
        }
    };

    private customizer = (oldValue: any, newValue: any): any => {
        if (newValue === undefined) return oldValue;
        return isObject(newValue) && !Array.isArray(newValue)
            ? assignWith(cloneDeep(oldValue), newValue, this.customizer)
            : newValue;
    };

    private onChangeFormInstance = (
        formInstance: FormInstance,
        form: Form,
    ): void => {
        const viewUpdateHook = formInstance.viewUpdate;
        this._form = viewUpdateHook
            ? viewUpdateHook({
                  user: CurrentUser.getDtoWithParsedToken(),
                  item: formInstance?.toDTO(),
                  form: cloneDeep(form),
              })
            : form;
    };

    private onRemoveFormInstance = async () => {
        const message = i18next.t("common:common.confirmDelete");
        try {
            this.logEvent({
                component: AnalyticsEventComponent.Action,
                action: AnalyticsEventAction.Click,
                params: { target: "delete" },
            });
            await ConfirmDialog("", message, {
                muiTheme: BaseTheme,
                confirmLabel: i18next.t("common:common.delete"),
                cancelLabel: i18next.t("common:common.cancel"),
                confirmUiTestId: "applyDelete",
                cancelUiTestId: "cancelButton",
                isAlert: true,
            });
            this.logEvent({
                component: AnalyticsEventComponent.Confirm,
                action: AnalyticsEventAction.Click,
                parent: AnalyticsEventParent.DeleteConfirm,
            });
            await this.removeFormInstance();
        } catch (e) {
            this.logEvent({
                component: AnalyticsEventComponent.Cancel,
                action: AnalyticsEventAction.Click,
                parent: AnalyticsEventParent.DeleteConfirm,
            });
        }
    };

    private removeFormInstance = async () => {
        try {
            if (!this.formInstance) return;
            await this.service.remove([this.formInstance.id]);
            this.onClose();
            this.dialogStateDataToDefault();
        } catch (e) {
            const message =
                (e as any).message || i18next.t("common:view.deleteError");
            Notify.error({ message });
        }
    };

    private canDelete = (): boolean => {
        try {
            return this.formInstance?.canDelete() || false;
        } catch (e) {
            return false;
        }
    };
}
