import {
    CommandBaseAction,
    CommandType,
    TProperty,
} from "@mrs/webclient-shared-ui-lib";
import get from "lodash-es/get";
import { ICommandHandler } from "../ICommandHandler";
import { lazyInject } from "../../../../core/AppDiContainer";
import { FormInstanceDiType } from "../../../../core/context/formInstance/diType";
import { IFormInstanceService } from "../../../../core/context/formInstance/service/IFormInstanceService";

export class FormInstanceDeleteCommand implements ICommandHandler {
    @lazyInject(FormInstanceDiType.IFormInstanceService)
    private readonly service: IFormInstanceService;

    get type() {
        return CommandType.FormInstance;
    }

    get action() {
        return CommandBaseAction.Delete;
    }

    execute = async (params: TProperty) => {
        const ids = get(params, "ids", []);
        if (!!ids.length) {
            await this.service.remove(ids);
        }
    };
}
