import { DiContainer } from "../core/AppDiContainer";
import { SUPPORTED_LANGUAGES, Translator } from "@translator";
import { ru } from "./locale/ru";
import { en } from "./locale/en";
import { ConfigAccess } from "@utils/ConfigAccess";
import { WsService } from "../infrastructure/websocket/WsService";
import { CurrentUser } from "../core/context/user/currentUser/CurrentUser";
import { Broadcast } from "../infrastructure/broadcast/broadcast";
import { AuthEvents } from "../core/context/auth/AuthEvents";
import { IRestService } from "../infrastructure/http/IRestService";
import { ContainerModule } from "inversify";
import { IQueryService } from "../infrastructure/queryService/IQueryService";
import { ApplicationDiType } from "./diType";
import { QueryService } from "../infrastructure/queryService/QueryService";
import { ConfigService } from "../core/context/config/service/ConfigService";
import { IConfigService } from "../core/context/config/service/IConfigService";
import { IAuthService } from "../core/context/auth/service/IAuthService";
import { AuthService } from "../core/context/auth/service/AuthService";
import lodash from "lodash-es";
import { LoggerService } from "@lib/logger/LoggerService";
import { SessionUtils } from "@utils/SessionUtils";
import { UserService } from "../core/context/user/service/UserService";
import { IUserService } from "../core/context/user/service/IUserService";
import { DictionaryEvents } from "../core/context/dictionary/DictionaryEvents";
import { User } from "../core/context/user/model/User";
import { UserCollection } from "../core/context/user/collection/UserCollection";
import { IUserCollection } from "../core/context/user/collection/IUserCollection";
import { ISupersetViewPresenter } from "@ui/components/view/SupersetView/presenter/ISupersetViewPresenter";
import { SupersetViewPresenter } from "./components/view/SupersetView/presenter/SupersetViewPresenter";
import { ViewType } from "@mrs/webclient-shared-ui-lib";
import { ColorUtils } from "@utils/color/ColorUtils";

export class ApplicationModule {
    private _userService!: IUserService;
    async start(restService: IRestService) {
        const queryService = new QueryService(restService);
        const configService = new ConfigService(restService);
        const authService = new AuthService(restService);
        this._userService = new UserService(queryService);
        const applicationModule = new ContainerModule((bind) => {
            /**
             * queryService
             */
            bind<IQueryService<any>>(
                ApplicationDiType.IQueryService,
            ).toConstantValue(queryService);
            /**
             * configService
             */
            bind<IConfigService>(
                ApplicationDiType.IConfigService,
            ).toConstantValue(configService);
            /**
             * authService
             */
            bind<IAuthService>(ApplicationDiType.IAuthService).toConstantValue(
                authService,
            );
            /**
             * userService
             */
            bind<IUserService>(ApplicationDiType.IUserService).toConstantValue(
                this._userService,
            );
            /**
             * view
             */
            bind<ISupersetViewPresenter>(ViewType.Superset)
                .to(SupersetViewPresenter)
                .inTransientScope();
        });

        DiContainer.load(applicationModule);

        const translator = Translator.getInstance();
        translator.addResource(SUPPORTED_LANGUAGES.RU, ru);
        translator.addResource(SUPPORTED_LANGUAGES.EN, en);

        await ConfigAccess.init();
        SessionUtils.init();
        const webSocketService = new WsService(
            ConfigAccess.config.server?.additionalWsStream || "",
        );
        const token = CurrentUser.getToken();
        if (token) {
            webSocketService.start();
            this.loadUserCollectionModule();
            const user = CurrentUser.getCurrentUser();
            if (user) {
                this.setDataByUser(user);
            } else {
                const result = await this._userService.getByToken(token);
                CurrentUser.setUser(result);
                this.setDataByUser(result);
            }
        }
        Broadcast.on(AuthEvents.logout, () => webSocketService.stop(), null);
        Broadcast.on(
            AuthEvents.login,
            () => {
                this.loadUserCollectionModule();
                webSocketService.start();
            },
            null,
        );

        (window as any).lodash = lodash;
        (window as any).changePinColor = ColorUtils.changePinColor;
    }

    private setDataByUser(user: User) {
        LoggerService.setUser({
            id: user.id,
            email: user.email,
        });

        Broadcast.on(
            [DictionaryEvents.onUpdated],
            async (event: any) => {
                if (
                    event.collection === ConfigAccess.config.userDictionary &&
                    event.id === user.id
                ) {
                    const newUser = await this._userService.getById(user.id);
                    newUser.accessToken = user.accessToken;
                    CurrentUser.setUser(newUser);
                }
            },
            null,
        );
    }

    private loadUserCollectionModule() {
        const userCollectionModule = new ContainerModule((bind) => {
            bind<IUserCollection>(
                ApplicationDiType.IUserCollection,
            ).toConstantValue(new UserCollection(this._userService));
        });

        DiContainer.load(userCollectionModule);
    }
}
