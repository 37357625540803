import {
    CommandBaseAction,
    CommandType,
    TProperty,
} from "@mrs/webclient-shared-ui-lib";
import { ICommandHandler } from "../ICommandHandler";
import { lazyInject } from "../../../../core/AppDiContainer";
import isEmpty from "lodash-es/isEmpty";
import { DictionaryDiType } from "../../../../core/context/dictionary/diType";
import { IDictionaryService } from "../../../../core/context/dictionary/service/IDictionaryService";

export class DictionaryChangeCommand implements ICommandHandler {
    @lazyInject(DictionaryDiType.IDictionaryService)
    private readonly service: IDictionaryService;

    get type() {
        return CommandType.Dictionary;
    }

    get action() {
        return CommandBaseAction.Change;
    }

    execute = async (params: TProperty) => {
        const { id, collectionName, ...changes } = params;
        if (!!collectionName && !isEmpty(changes)) {
            await this.service.updateDictionaryItem(id, changes, collectionName);
        } else {
            console.log("Missing collectionName:", collectionName, "or changes:", changes);
        }
    };
}
