import {
    IViewWithSearchManagerInitParams,
    IViewWithSearchManagerParams,
    IViewWithSearchManagerPresenter,
} from "./IViewWithSearchManagerPresenter";
import { computed, observable } from "mobx";
import { injectable } from "inversify";
import { View } from "../../../../../core/context/view/model/View";
import {
    IViewParams,
    TVoidCallback,
    ViewMode,
    ViewUtils,
    Nullable,
    QueryName,
} from "@mrs/webclient-shared-ui-lib";
import { WithViewComponentClass } from "@ui/components/managers/base/WithViewComponent";
import get from "lodash-es/get";
import debounce from "lodash-es/debounce";
import { ConfigurationAccess } from "../../../../../core/context/configuration/ConfigurationAccess";
import { ViewModuleInitializer } from "@ui/components/view/shared/ViewModuleInitializer";
import { Notify } from "@utils/notify/Notify";
import { BaseTheme } from "@ui/theme/baseTheme/BaseTheme";
import { CurrentUser } from "../../../../../core/context/user/currentUser/CurrentUser";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";

@injectable()
export class ViewWithSearchManagerPresenter extends WithViewComponentClass
    implements IViewWithSearchManagerPresenter {
    protected readonly _multiple: boolean = false;

    @observable private _searchValue: string = "";
    @observable private _isSearchEnabled: boolean = false;

    private _view: Nullable<View> = null;
    private _onChange: TVoidCallback<any[]>;

    private searchView = debounce(async (variables: object) => {
        await this._viewComponentPresenter?.searchView(variables);
    }, 300);

    constructor(params: IViewWithSearchManagerParams) {
        super();
        this._multiple = !!params.multiple;
        this.init = this.init.bind(this);
        this.unmount = this.unmount.bind(this);
    }

    @computed
    public get isSearchEnabled(): boolean {
        return this._isSearchEnabled;
    }

    @computed
    get searchValue(): string {
        return this._searchValue;
    }

    async init({
        viewName,
        values,
        scrollableTarget,
        variables,
        onChange,
        maxSelectedCount,
    }: IViewWithSearchManagerInitParams): Promise<void> {
        try {
            const view = await ConfigurationAccess.getView(viewName);
            if (view) {
                this._view = view;
                this._onChange = onChange;
                const initializer = new ViewModuleInitializer(
                    view,
                    view.viewType,
                );
                await initializer.init();
                const { presenter, queries, initView } = initializer;
                this._viewComponentPresenter = presenter;
                this._isSearchEnabled = !!get(queries, QueryName.Search);

                const params = {
                    onSelect: this.onSelect,
                    selectedItems: values,
                    multiple: this._multiple,
                    mode: ViewMode.SELECTABLE,
                    scrollableTarget,
                    maxSelectedCount,
                };
                await initView(params, variables || {});
            }
        } catch (e) {
            console.log("Error on getting view: ", e);
        }
    }

    unmount() {
        this._viewComponentPresenter?.clearView();
        this._viewComponentPresenter = null;
        this._searchValue = "";
        this._view = null;
    }

    onChangeSearchValue = async (value: string) => {
        this._searchValue = value;
        const variables = ViewUtils.toVariablesSearchString(value);
        await this.searchView(variables);
    };

    setDisabled = (value: boolean) => {
        this._viewComponentPresenter?.setDisabled(value);
    };

    private onSelect = async (els: any[]) => {
        this._onChange(els);
    };
}
