import {
    CommandType,
    TProperty,
    ConfirmDialog,
    AnalyticsEventComponent,
    AnalyticsEventAction,
    AnalyticsEventParent,
    CommandBaseAction,
} from "@mrs/webclient-shared-ui-lib";
import { ICommandHandler } from "../ICommandHandler";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";
import { BaseTheme } from "@ui/theme/baseTheme/BaseTheme";
import i18next from "@lib/translator/i18next";
import { ClientException } from "../../../../core/sharedKernel/error/ClientException";

export class ConfirmationDeleteCommand implements ICommandHandler {
    get type() {
        return CommandType.Confirmation;
    }

    get action() {
        return CommandBaseAction.Delete;
    }

    execute = async (params: TProperty) => {
        const { analyticsEvent } = params || {};
        const { analyticsParams, ...other } = analyticsEvent || {};

        const message = i18next.t("common:common.confirmDelete");

        try {
            await ConfirmDialog("", message, {
                muiTheme: BaseTheme,
                confirmLabel: i18next.t("common:common.delete"),
                cancelLabel: i18next.t("common:common.cancel"),
                confirmUiTestId: "applyDelete",
                cancelUiTestId: "cancelButton",
                isAlert: true,
            });
            AnalyticsService.logEvent({
                component: AnalyticsEventComponent.Confirm,
                action: AnalyticsEventAction.Click,
                parent: AnalyticsEventParent.DeleteConfirm,
                params: analyticsParams,
                ...other,
            });
        } catch (e) {
            AnalyticsService.logEvent({
                component: AnalyticsEventComponent.Cancel,
                action: AnalyticsEventAction.Click,
                parent: AnalyticsEventParent.DeleteConfirm,
                params: analyticsParams,
                ...other,
            });
            throw new ClientException("Cancel delete confirmation dialog");
        }
    };
}
