import {
    CommandType,
    CommandWindowAction,
    TProperty,
} from "@mrs/webclient-shared-ui-lib";
import { ICommandHandler } from "../ICommandHandler";
import { useInject } from "../../../../core/AppDiContainer";
import { FormInstanceDiType } from "../../../../core/context/formInstance/diType";
import { IViewItemDialogPresenter } from "@ui/components/view/uiInterfaces/IViewItemDialogPresenter";

export class FormCloseCommand implements ICommandHandler {
    get type() {
        return CommandType.Form;
    }

    get action() {
        return CommandWindowAction.Close;
    }

    execute = async (params: TProperty) => {
        const dialogPresenter = useInject<IViewItemDialogPresenter>(
            FormInstanceDiType.FormInstanceDialogPresenter,
        );
        if (!!dialogPresenter && !!params.force) {
            dialogPresenter.onClose();
        }
    };
}
