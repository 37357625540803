import {
    CommandBaseAction,
    CommandType,
    TProperty,
} from "@mrs/webclient-shared-ui-lib";
import { ICommandHandler } from "../ICommandHandler";
import { lazyInject } from "../../../../core/AppDiContainer";
import { DictionaryDiType } from "../../../../core/context/dictionary/diType";
import { IDictionaryService } from "../../../../core/context/dictionary/service/IDictionaryService";
import isEmpty from "lodash-es/isEmpty";

export class DictionaryCreateCommand implements ICommandHandler {
    @lazyInject(DictionaryDiType.IDictionaryService)
    private readonly service: IDictionaryService;

    get type() {
        return CommandType.Dictionary;
    }

    get action() {
        return CommandBaseAction.Create;
    }

    execute = async (params: TProperty) => {
        const { collectionName, ...creationData } = params;
        if (!!collectionName && !isEmpty(creationData)) {
            await this.service.createDictionaryItem(creationData, collectionName);
        } else {
            console.log("Missing collectionName:", collectionName, "or creationData:", creationData);
        }
    };
}
